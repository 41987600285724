import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
	name: 'application',
	initialState: {
		isSidebarOpen: true,
	},
	reducers: {
		setIsSidebarOpen(state, action) {
			state.isSidebarOpen = action.payload
		},
	},
})

export default slice

export const { name, actions, reducer } = slice
