import Modal from './Modal'
import * as selectors from './modal.selectors'
import * as slice from './modal.reducers'

export const {
	name,
	actions: { setSelectedModal },
	reducer,
} = slice

export const { selectSelectedModal } = selectors

export default Modal
