import { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { crossIconWhite } from '../../../assets/icons'

import {
	MODAL_SIZE,
	MODAL_POSITION,
	KEYDOWN,
	ESCAPE,
	MODALS,
} from '../../../constants.js'
import { selectSelectedModal } from './index.js'

const { MD, FULL, LG } = MODAL_SIZE
const { CENTER } = MODAL_POSITION
const { INITIAL_MODAL, DISCLAIMER_MODAL } = MODALS

const Modal = ({
	children,
	handleClose,
	title,
	footer,
	size = FULL,
	position = CENTER,
	subtitle,
	shouldOpen,
}) => {
	const selectedModal = useSelector(selectSelectedModal)
	const [shouldShow, setShouldShow] = useState(false)
	const backdropRef = useRef(null)

	useEffect(() => {
		setShouldShow(selectedModal || shouldOpen)
	}, [selectedModal, shouldOpen])

	useEffect(() => {
		if (selectedModal === INITIAL_MODAL || selectedModal === DISCLAIMER_MODAL) {
			const close = ({ code }) => code === ESCAPE && handleClose()
			document.addEventListener(KEYDOWN, close)
			return () => document.removeEventListener(KEYDOWN, close)
		}
	}, [handleClose, selectedModal])

	return (
		<div
			className={classNames(
				'modal-backdrop fixed inset-0 bg-slate-900/70 backdrop-blur-md z-[999] overflow-auto transition-opacity duration-300 custom-scrollbar',
				{
					' px-4 py-16 lg:flex': size === MD || LG,
					' items-center': (size === MD || LG) && position === CENTER,
					' inline-block opacity-100': !!shouldShow,
					' w-0 h-0 opacity-0': !shouldShow,
				}
			)}
			onClick={handleClose}
		>
			<div
				ref={backdropRef}
				onClick={(e) => e.stopPropagation()}
				className={classNames(`modal-content bg-black w-full mx-auto`, {
					' my-6 sm:w-[600px] h-fit': size === MD,
					'flex flex-col fixed inset-y-0 right-0 sm:w-[42vw] max-h-[100vh] rounded-none md:rounded-lg':
						size === LG,
					' flex flex-col max-w-[884px]': size === FULL,
				})}
			>
				{title && (
					<div className='flex gap-4 items-center p-4 border-b border-gray-700'>
						<h6
							className={classNames(
								' text-gray-50 heading md:text-2xl text-xl'
							)}
						>
							{title}
						</h6>
						{handleClose && (
							<button
								type='button'
								className='ml-auto rounded-md hover:opacity-50'
								onClick={() => handleClose()}
							>
								<img src={crossIconWhite} alt='cross' className='w-6' />
							</button>
						)}
					</div>
				)}
				{subtitle && (
					<div className='text-base px-4 py-6 overflow-auto scrollbar-hide text-secondary-light leading-relaxed'>
						{subtitle}
					</div>
				)}
				{children && <div className='p-2'>{children}</div>}
				{footer && (
					<div className='modal-footer p-4 border-t border-gray-700'>
						{footer}
					</div>
				)}
			</div>
		</div>
	)
}

export default Modal
