import React from 'react'
import SidebarNavLink from './NavbarLink'

const SidebarNav = ({ style, navLinks = [] }) => (
	<nav className={`${style} px-2 py-6`}>
		<ul className='navbar-nav flex flex-col gap-1'>
			{navLinks.map((each) => (
				<li key={each.id}>
					<SidebarNavLink each={each} />
				</li>
			))}
		</ul>
	</nav>
)

export default SidebarNav
