import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import ReactPaginate from 'react-paginate'
import { chevronLeft, chevronRight } from '../../assets/icons'
import { EMPTY_STRING } from '../../constants'

const rowsPerPage = [5, 10, 15]

const Pagination = ({
	limit = 1,
	totalPage = 1,
	totalCount,
	currentPage = 1,
	handlePagination,
}) => {
	const [startRange, setStartRange] = useState(1)
	const [endRange, setEndRange] = useState(Math.min(totalCount, limit))

	const handlePaginationAndUpdate = useCallback(
		(nextPage) => {
			setStartRange((nextPage - 1) * limit + 1)
			setEndRange(Math.min(nextPage * limit, totalCount))
			handlePagination({ limit, page: nextPage })
		},
		[handlePagination, limit, totalCount]
	)

	const handlePrevious = () => {
		const nextPage = currentPage === 1 ? 1 : currentPage - 1
		handlePaginationAndUpdate(nextPage)
	}

	const handleNext = () => {
		const nextPage = currentPage === totalPage ? totalPage : currentPage + 1
		handlePaginationAndUpdate(nextPage)
	}

	const handleLimitUpdate = useCallback(
		(newLimit) => {
			setStartRange(1)
			setEndRange(Math.min(newLimit, totalCount))
			handlePagination({ limit: newLimit, page: 1 })
		},
		[totalCount, handlePagination]
	)

	return (
		<div className='table-pagination px-6 py-2 border-t border-light-gray flex items-center gap-6 justify-between md:justify-end'>
			<span className='text-sm text-white max-md:hidden'>Rows per page : </span>

			<select
				className='bg-transparent text-white text-sm p-0 outline-none'
				onChange={(e) => handleLimitUpdate(e.target.value)}
				value={limit}
			>
				{rowsPerPage.map((item) => (
					<option key={item} value={item} className='text-black cursor-pointer'>
						{item}
					</option>
				))}
			</select>
			<span className='text-sm text-white'>
				{startRange} - {endRange} of {totalCount}
			</span>
			<div className='flex items-center gap-2'>
				<button
					className='p-2 hover:bg-primary/10 rounded-md disabled:cursor-not-allowed'
					onClick={handlePrevious}
					disabled={currentPage <= 1}
				>
					<img
						src={chevronLeft}
						alt='arrow-left'
						className={classNames({
							'opacity-60': currentPage <= 1,
						})}
					/>
				</button>
				<div className='col-auto'>
					<ReactPaginate
						forcePage={currentPage}
						nextLabel={EMPTY_STRING}
						previousLabel={EMPTY_STRING}
						containerClassName='flex justify-end items-center'
						breakClassName='text-contrast-600 pt-1'
						activeClassName='flex items-center justify-end rounded-full border-light bg-primary-600 text-contrast-200'
						pageCount={totalPage}
						renderOnZeroPageCount={null}
						pageLabelBuilder={() => EMPTY_STRING}
					/>
				</div>
				<button
					className='p-2 hover:bg-primary/10 rounded-md disabled:cursor-not-allowed'
					onClick={handleNext}
					disabled={currentPage === totalPage}
				>
					<img
						src={chevronRight}
						alt='arrow-right'
						className={classNames({
							'opacity-60': currentPage === totalPage,
						})}
					/>
				</button>
			</div>
		</div>
	)
}

export default Pagination
