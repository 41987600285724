import React from 'react'
import { instructionsGif } from '../../assets/icons'

const Instructions = () => (
	<div className='col-span-12 bg-secondary p-4 flex flex-col md:hidden gap-4'>
		<h4 className='text-sm font-medium text-white'>Instructions</h4>
		<div className='flex md:hidden gap-4 items-center'>
			<div className='icon shrink-0'>
				<img src={instructionsGif} width={60} alt='swipeRight' />
			</div>
			<p className='text-sm text-secondary-light ml-[-15px]'>
				To move Defender left and right, tap and hold down the left and right
				sides of your mobile screen.
			</p>
		</div>
	</div>
)

export default Instructions
