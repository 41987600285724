import { useDispatch } from 'react-redux'
import { actions } from './components/organisms/Modal/modal.reducers'
import { MODALS, IS_AUTHENTICATED } from './constants'
const { setSelectedModal } = actions
const { ACCESS_DENIED_MODAL } = MODALS

const Authentication = ({ children }) => {
	const dispatch = useDispatch()
	const isAuthenticated = localStorage.getItem(IS_AUTHENTICATED)

	if (isAuthenticated) return children
	else dispatch(setSelectedModal(ACCESS_DENIED_MODAL))
}

export default Authentication
