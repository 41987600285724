const getQueryParams = (filters) => {
	let string = ''
	Object.keys(filters).forEach((each) => {
		if (filters[each]) {
			string = string.length
				? string + `&${each}=${filters[each]}`
				: string + `${each}=${filters[each]}`
		}
	})
	return string
}
export default getQueryParams
