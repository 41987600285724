import { combineReducers } from 'redux'
import * as modal from './components/organisms/Modal'
import * as spinner from './components/organisms/Spinner'
import * as app from './pages/AppReducers'
import * as leaderboard from './pages/Leaderboard'
import * as auth from './components/organisms/Auth'

export default combineReducers({
	[modal.name]: modal.reducer,
	[spinner.name]: spinner.reducer,
	[app.name]: app.reducer,
	[auth.name]: auth.reducer,
	[leaderboard.name]: leaderboard.reducer,
})
