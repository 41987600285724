import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { spinnerIcon } from '../../../assets/icons'
import { selectSelectedSpinner } from './index'

const Spinner = ({ name }) => {
	const spinners = useSelector(selectSelectedSpinner)
	const [shouldShowSpinner, setShouldShowSpinner] = useState(!spinners.length)

	useEffect(() => {
		setShouldShowSpinner(spinners.some((spinnerName) => spinnerName === name))
	}, [name, spinners])

	return (
		shouldShowSpinner && (
			<span className={classNames('bg-opacity-70')}>
				<img
					src={spinnerIcon}
					className='inline animate-spin h-6 w-8'
					alt='spinner-icon'
				/>
			</span>
		)
	)
}
export default Spinner
