import { formatTime, quizFormatter, badgesFormatter } from '../../helper'

const Card = ({ item }) => {
	return (
		<div className='w-full  p-2 border-b border-gray-600' key={item.id}>
			<div className='grid grid-cols-[0.4fr,1.75fr,1fr] gap-4'>
				<div className='text-white flex flex-col items-center justify-center'>
					<p className='font-semibold text-[14px] pt-5'>{item.rank}</p>
				</div>
				<div className='flex flex-col'>
					<div className='flex items-center'>
						<span className='font-semibold text-white text-[14px] mb-2'>
							{item.userDisplayName}
						</span>
					</div>

					<div className='flex justify-between'>
						<div className='flex flex-col'>
							<span className='text-secondary-light text-[12px]'>Score</span>
							<span className='text-secondary-light text-[12px] py-1'>
								Survival Time
							</span>
							<span className='text-secondary-light text-[12px]'>Quiz</span>
						</div>
						<div className='flex flex-col'>
							<span className='text-white text-start text-[14px]'>
								{item.score}
							</span>
							<span className='text-white text-start text-[14px]'>
								{formatTime(item.timePlayed)}
							</span>
							<span className='text-white text-start text-[14px]'>
								{quizFormatter(item.grade)}
							</span>
						</div>
					</div>
				</div>

				<div className='flex flex-col items-end pr-1'>
					<p className='text-secondary-light font-semibold text-[12px]'>
						Level
					</p>
					<p className='text-white text-[14px] pr-2'>{item.level}</p>
				</div>
			</div>
			<div className=' w-full py-1 pl-12'>
				{badgesFormatter(item.metadata.upgrades)}
			</div>
		</div>
	)
}

export default Card
