import { useSelector } from 'react-redux'
import SidebarNav from './SidebarNav'
import { selectIsSidebarOpen } from '../../../pages/AppReducers'
import { sidebarNavData, bottomNavData } from './sidebar.navData'
import { logo } from '../../../assets/icons'

const Sidebar = () => {
	const isSidebarOpen = useSelector(selectIsSidebarOpen)

	return (
		<div className='main-wrapper h-screen lg:flex hidden'>
			<aside
				className={`${
					isSidebarOpen ? 'w-60' : 'w-20'
				}  bg-black  border-r border-gray-700 flex flex-col transition-all ease-in-out duration-300`}
			>
				<div className='brand px-4 py-4 h-16'>
					<img src={logo} width={85} className='max-w-full' alt='logo' />
				</div>
				<SidebarNav navLinks={sidebarNavData} />
				<SidebarNav style='mt-auto' navLinks={bottomNavData} />
			</aside>
		</div>
	)
}

export default Sidebar
