import http from '../httpMethods'
import { actions } from '../../components/organisms/Spinner/spinner.reducer'
const { removeSpinner, addSpinner } = actions
import { HTTP_METHODS } from '../../constants'
const { GET } = HTTP_METHODS

export const handleException = (exception, methodType, url) => {
	console.log(
		`Unable to process this ${methodType} request 
      for end-point ${url}
      Failed with response:`,
		exception.status
	)
}

export const createHeader = (httpHeaders) => {
	const accessToken = 'token'
	const requestOptions = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
		},
	}

	return httpHeaders
		? {
				headers: {
					...requestOptions.headers,
					...httpHeaders.headers,
					Authorization: `Bearer ${accessToken}`,
				},
		  }
		: requestOptions
}

const asyncAction = ({
	url,
	methodType = GET,
	spinner,
	dispatch,
	httpHeaders = {},
}) => {
	if (spinner) dispatch(addSpinner(spinner))

	httpHeaders = {
		...httpHeaders,
		...createHeader(httpHeaders),
	}
	return http[methodType](url, httpHeaders)
		.then((body) => {
			if (spinner) dispatch(removeSpinner(spinner))
			return Promise.resolve(body)
		})
		.catch((exception) => {
			handleException(exception, methodType, url)
			if (spinner) dispatch(removeSpinner(spinner))
		})
		.then((response) => response)
}

export default asyncAction
