import React, { useState } from 'react'
import classNames from 'classnames'
import KeyInstructions from './KeyInstructions'
import Instructions from './Instructions'
import { gameThumbnailSmall } from '../../assets/images'

const prizeWrtPeriodTable = [
	{
		period: 'Q1 (through March 31st)',
		prize: 'Top 20 scores are eligible for $20',
	},
	{
		period: 'Q2 (April 1st through June 30th)',
		prize: 'Top 10 scores are eligible for $50',
	},
	{
		period: 'Q3 (July 1st through September 30th)',
		prize: 'Top 5 scores are eligible for $75',
	},
	{
		period: 'Q4 (October 1st through December 31st)',
		prize: 'Top 3 scores are eligible for $100',
	},
]

const GameInsights = ({ isRenderedInModal, children }) => {
	const [shouldViewAllText, setShouldViewAllText] = useState(false)

	return (
		<div
			className={classNames('bg-black p-6 grid grid-cols-12 gap-6', {
				'p-1': isRenderedInModal,
			})}
		>
			<div
				className={classNames('col-span-2 hidden md:block', {
					'!hidden': isRenderedInModal,
				})}
			>
				<img
					src={gameThumbnailSmall}
					className={classNames(
						'h-40 object-cover rounded border border-white'
					)}
					alt='thumbnail'
				/>
			</div>
			<Instructions />
			<div
				className={classNames('col-span-12 md:col-span-8 gap-1', {
					'col-span-12 md:col-span-9 mt-1': isRenderedInModal,
				})}
			>
				{isRenderedInModal ? (
					<>{children}</>
				) : (
					<>
						<h4 className='font-bold text-white text-xl pb-2'>
							Survive the Vulnerability Onslaught In Digital Defender!
						</h4>
						<div className='text-sm overflow-auto scrollbar-hide text-secondary-light leading-relaxed'>
							<h3 className='subtitle text-white text-base leading-6 pb-4'>
								How to Play
							</h3>
							<p className='text-base text-secondary-light gap-1 overflow-auto'>
								In this game, you’ll be presented with a series of knowledge
								blocks and quiz question, which will earn you powerful upgrades
								to enhance your mission. Complete each knowledge block and pass
								the quizzes to surv
								{shouldViewAllText
									? 'ive against attackers. If your health reaches zero or you answer incorrectly 3 times, it’s “Game Over” for Defender. Simply tap “Replay” to start your defense over again. Quiz questions are random—every game is a unique challenge.'
									: '...'}
							</p>
							{shouldViewAllText && (
								<div className='text-base text-secondary-light gap-1 overflow-auto scrollbar-hide'>
									<h3 className='subtitle text-white text-base leading-6 py-4'>
										Accessibility
									</h3>
									<p className='text-base text-secondary-light gap-1'>
										To access commercial client training covering the launch of
										Intel® Core™ Ultra and Intel® Core™ processors (14th gen)
										that is accessible to users with disabilities, please visit
										the primary ClientU web portal at:{' '}
										<a
											href='https://goto.intel.com/clientu'
											target='_blank'
											rel='noReferrer'
											className='text-white underline'
										>
											https://goto.intel.com/clientu
										</a>
									</p>
									<h3 className='subtitle text-white text-base leading-6 py-4'>
										Score with a High Score
									</h3>
									<p className='text-base text-secondary-light gap-1'>
										Every quarter of 2024 is an open door to score monetary
										prizes for completing this training. At the end of each
										quarter, if your high score is on the leaderboard at the
										following rankings, you’ll qualify to win. The leaderboard
										will be reset and the knowledge blocks and quiz questions
										will be refreshed at the beginning of each quarter. So, if
										you didn’t qualify in a previous quarter, your chance to win
										resets every quarter too! Only Intel full time employees
										(Blue Badge holders) are eligible.
									</p>
									<div className='my-4'>
										<table className='w-full'>
											<thead>
												<tr>
													<th className='px-4 py-2 w-1/2 text-secondary-light text-left border border-[#262626]'>
														Period
													</th>
													<th className='px-4 py-2 w-1/2 text-secondary-light text-left border border-[#262626]'>
														Prize per User
													</th>
												</tr>
											</thead>
											<tbody>
												{prizeWrtPeriodTable.map((item, index) => (
													<tr key={index}>
														<td className='px-4 py-2 w-1/2 text-secondary-light border border-[#262626]'>
															{item.period}
														</td>
														<td className='px-4 py-2 w-1/2 text-secondary-light border border-[#262626] '>
															{item.prize}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
									<ul className='list-disc pl-6 list-[square]'>
										<li>Users are eligible to win in more than one quarter.</li>
										<li>
											A user may only have one high score on the leaderboard per
											quarter. If a user replays the game and generates a higher
											score, their previous high score is replaced with the new
											high score.
										</li>
									</ul>
									<h3 className='subtitle text-white text-base leading-6 py-4'>
										Leaderboard Opt In
									</h3>
									<p className='text-base text-secondary-light gap-1'>
										Upon completion of this training and hitting “Submit” on the
										game-over screen, your name may be shared on the game
										leaderboard and in our internal communication channels such
										as Viva Engage (formerly Yammer) and Circuit+ for
										recognition and program promotion efforts only; no other
										personal data is included.
									</p>
									<p className='text-base pt-4 text-secondary-light gap-1'>
										If you would like your name to be excluded from the
										leaderboard and internal communications, close the game over
										screen without hitting “Submit.” For more information on how
										Intel manages your personal data, see the &nbsp;
										<a
											href='http://employeecontent.intel.com/content/corp/Global_Employee_and_Global_Contingent_Worker_Privacy.html'
											target='_blank'
											className='subtitle text-base leading-6 text-white pt-1'
											rel='noReferrer'
										>
											Global Employee and Contingent Worker Privacy Notice.
										</a>
									</p>
								</div>
							)}
						</div>

						<button
							className={classNames(
								'text-energy-blue inline-flex pt-4 text-xs gap-2',
								{
									'pt-8': !shouldViewAllText,
								}
							)}
							onClick={() => setShouldViewAllText(!shouldViewAllText)}
						>
							View {shouldViewAllText ? 'Less' : 'More'}
						</button>
					</>
				)}
			</div>
			<KeyInstructions isRenderedInModal={isRenderedInModal} />
		</div>
	)
}

export default GameInsights
