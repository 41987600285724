import React from 'react'
import { Route, Routes } from 'react-router'
import LandingPage from './components/organisms/LandingPage'
import Leaderboard from './pages/Leaderboard'
import Game from './pages/Game'
import Authentication from './Authentication'
import { ROUTES } from './constants'

const { HOME, LEADERBOARD, PLAY } = ROUTES

const RoutesLayout = () => (
	<Routes>
		<Route exact path={HOME} element={<LandingPage />} />
		<Route
			exact
			path={LEADERBOARD}
			element={
				<Authentication>
					<Leaderboard />
				</Authentication>
			}
		/>
		<Route
			exact
			path={PLAY}
			element={
				<Authentication>
					<Game />
				</Authentication>
			}
		/>
	</Routes>
)

export default RoutesLayout
