import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import { get, isEmpty } from 'lodash'
import Spinner from '../organisms/Spinner'
import { FETCH_LEADERBOARD } from '../../constants'

const renderField = (column, row) => {
	if (column.formatter) {
		const data = get(row, column.dataField)
		return column.formatter(data)
	}
	return get(row, column.dataField)
}

const Table = ({ columns = [], data = [], limit, spinner }) => {
	return (
		<table className='table w-full divide-y divide-light-gray'>
			<thead>
				<tr className='text-secondary-light text-[13px]'>
					{columns.map((column) => (
						<th
							key={uuid()}
							width={column.width}
							className={classNames(
								'font-normal text-left p-4 ',
								column.className
							)}
						>
							{column.text}
						</th>
					))}
				</tr>
			</thead>
			<tbody className='text-sm divide-y divide-light-gray'>
				<tr
					className={classNames('h-[363px]', {
						'h-[500px]': limit > 5,
						hidden: !spinner,
					})}
				>
					<td className='text-center' colSpan={columns.length}>
						<Spinner name={FETCH_LEADERBOARD} />
					</td>
				</tr>
				{isEmpty(data) ? (
					<tr className={classNames('h-[200px]', { hidden: spinner })}>
						<td
							className='py-6 px-8 text-center text-white rounded-b-lg'
							colSpan={columns.length}
						>
							<b>No Data to display</b>
						</td>
					</tr>
				) : (
					data.map((row) => (
						<tr
							key={uuid()}
							className={classNames('text-white pl-2', { hidden: spinner })}
						>
							{columns.map((column) => (
								<td className='p-4 gap-4 first:text-center' key={uuid()}>
									{renderField(column, row)}
								</td>
							))}
						</tr>
					))
				)}
			</tbody>
		</table>
	)
}

export default Table
