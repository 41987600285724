import { ROUTES, TABS, MODALS } from '../../../constants'
const { HOME, LEADERBOARD, LOGOUT } = ROUTES
const { DISCLAIMER_MODAL } = MODALS
const { PLAY_NOW, LEADER_BOARD, DISCLAIMER: DISCLAIMER_TEXT, LOG_OUT } = TABS
import {
	documentIcon,
	graphIcon,
	homeIcon,
	logOutIcon,
} from '../../../assets/icons'

export const sidebarNavData = [
	{
		id: 1,
		title: PLAY_NOW,
		icon: homeIcon,
		path: HOME,
	},
	{
		id: 2,
		title: LEADER_BOARD,
		icon: graphIcon,
		path: LEADERBOARD,
	},
]

export const bottomNavData = [
	{
		id: 3,
		title: DISCLAIMER_TEXT,
		icon: documentIcon,
		renderModal: DISCLAIMER_MODAL,
		handleClick: true,
	},
	{
		id: 4,
		title: LOG_OUT,
		icon: logOutIcon,
		handleClick: () => (window.location.href = LOGOUT),
	},
]
