import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
	name: 'modal',
	initialState: {
		selectedModal: null,
	},
	reducers: {
		setSelectedModal(state, action) {
			state.selectedModal = action.payload
		},
	},
})

export default slice

export const { name, actions, reducer } = slice
