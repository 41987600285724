import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '../../components/atoms/Pagination'
import leaderboardColumns from './leaderboard.column'
import Table from '../../components/atoms/Table'
import { actions } from './leaderboard.reducers'
import { selectLeaderboardData, selectFilters, fetchLeaderboard } from './index'
import Cards from '../../components/atoms/Cards'
import { selectSelectedSpinner } from '../../components/organisms/Spinner'
import { FETCH_LEADERBOARD } from '../../constants'
const { setFilters } = actions

const Leaderboard = () => {
	const dispatch = useDispatch()
	const leaderboardData = useSelector(selectLeaderboardData)
	const filters = useSelector(selectFilters)
	const spinner = useSelector(selectSelectedSpinner).find(
		(item) => item == FETCH_LEADERBOARD
	)

	const [paginatedData, setPaginatedData] = useState({
		data: [],
		currentPage: 1,
		limit: 10,
		total: 0,
	})

	useEffect(() => {
		dispatch(fetchLeaderboard(filters))
	}, [dispatch, filters])

	useEffect(() => {
		if (leaderboardData) {
			const { page, pageSize, items, total } = leaderboardData
			// add rank to each row
			const rankedItems = items.map((item, index) => {
				return { ...item, rank: (page - 1) * pageSize + index + 1 }
			})
			setPaginatedData({
				data: rankedItems,
				currentPage: page,
				limit: pageSize,
				total,
			})
		}
	}, [leaderboardData])

	const handlePagination = useCallback(
		(value) => {
			dispatch(
				setFilters({
					...filters,
					page: value.page,
					pageSize: value.limit,
				})
			)
		},
		[dispatch, filters]
	)

	const totalPage = Math.ceil(paginatedData.total / paginatedData.limit)

	return (
		<main className='flex-1 overflow-auto p-6 md:pt-16 pt-20 custom-scrollbar'>
			<div className='flex flex-col gap-6'>
				<div className='bg-black'>
					<div className='p-6 gap-4'>
						<h2 className='font-bold text-lg text-white'>Leaderboard</h2>
					</div>
					<div className='md:hidden'>
						<Cards data={paginatedData.data} spinner={spinner} />
					</div>
					<div className='w-[86vw] md:w-full overflow-x-scroll border-b border-light-gray m-auto scrollbar-hide max-h-[70vh] hidden md:block'>
						<Table
							columns={leaderboardColumns}
							data={paginatedData.data}
							limit={paginatedData.limit}
							spinner={spinner}
						/>
					</div>
					{totalPage > 1 && (
						<Pagination
							totalPage={totalPage}
							currentPage={paginatedData.currentPage}
							totalCount={paginatedData.total}
							limit={paginatedData.limit}
							handlePagination={handlePagination}
						/>
					)}
				</div>
			</div>
		</main>
	)
}

export default Leaderboard
