import { formatTime, quizFormatter, badgesFormatter } from '../../helper'

const leaderboardColumns = [
	{
		text: 'Rank',
		dataField: 'rank',
		width: 70,
		className: 'text-center',
	},
	{
		text: 'Player',
		width: 200,
		dataField: 'userDisplayName',
	},
	{
		text: 'Quiz',
		dataField: 'grade',
		formatter: quizFormatter,
	},
	{
		text: 'Score',
		dataField: 'score',
	},
	{
		text: 'Survival Time',
		dataField: 'timePlayed',
		formatter: formatTime,
	},
	{
		text: 'Level',
		dataField: 'level',
	},
	{
		text: 'Defender Upgrades',
		dataField: 'metadata.upgrades',
		formatter: badgesFormatter,
	},
]

export default leaderboardColumns
