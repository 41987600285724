import { createAsyncThunk } from '@reduxjs/toolkit'
import getQueryParams from '../../helper/getQueryParams'
import asyncAction from '../../infrastructure/asyncAction/asyncAction'
import { FETCH_LEADERBOARD } from '../../constants'
import { HTTP_METHODS } from '../../constants'
const { GET } = HTTP_METHODS

export const fetchLeaderboard = createAsyncThunk(
	'fetch-leaderboardData',
	async (filters, args) => {
		const queryParams = getQueryParams(filters)
		return asyncAction({
			url: `game/score?${queryParams}`,
			methodType: GET,
			spinner: FETCH_LEADERBOARD,
			...args,
		})
	}
)
