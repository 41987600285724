import { useDispatch } from 'react-redux'
import Modal from '../../Modal'
import { actions } from '../../Modal/modal.reducers'
import { MODAL_SIZE } from '../../../../constants'
import Button from '../../../atoms/Button'
const { setSelectedModal } = actions
const { LG } = MODAL_SIZE

const DisclaimerModal = () => {
	const dispatch = useDispatch()

	const handleClose = () => dispatch(setSelectedModal(null))

	const footer = (
		<div className='flex align-center justify-end'>
			<Button
				name='Close'
				classes='text-white content-end '
				onClick={handleClose}
			/>
		</div>
	)
	const disclaimerContent = (
		<div className='text-sm px-4 overflow-scroll scrollbar-hide text-secondary-light leading-relaxed'>
			<h3 className='text-white text-base title leading-7 mb-4 font-bold'>
				Limited Distribution—Not for End Users
			</h3>
			<h3 className='subtitle text-white text-base leading-6'>
				Not Intended for End Users
			</h3>
			<ul className='list-disc pl-8 mb-4 list-[square]'>
				<li>
					This material is tailored for a technically savvy audience and
					therefore is not intended for end users (i.e., mainstream consumers).
				</li>
				<li>
					Each slide must include the label “Limited Distribution – Not for End
					Users”
				</li>
			</ul>
			<h3 className='subtitle text-white text-base leading-6'>
				Usage Instructions
			</h3>
			<ul className='list-disc pl-8 mb-4 list-[square]'>
				<li>
					This material may NOT be posted on a public website or posted in
					retail stores or otherwise shared with consumer end users.
				</li>
				<li>
					The material is intended for Intel and its partners under NDA.
					Distribution beyond Intel and its NDA partners is permitted but
					limited to:
					<ul className='list-disc pl-8 list-[square]'>
						<li>OEM, retail (RSPs), and channel sales personnel</li>
						<li>
							Sophisticated institutional or corporate customers (e.g. large
							enterprise, government and education sector customers)
						</li>
					</ul>
				</li>
				<li>
					Do not alter the material in any way, including by removing
					disclaimers, system configuration details, or appendices or sharing
					slides on a standalone basis.
				</li>
				<li>
					Contact your Intel representative if you have questions or want to
					share the material in a different way than described here.
				</li>
				<li>
					Publication date: December 2023. Please contact your Intel
					representative for up-to-date information.
				</li>
			</ul>
			<p className='text-xs leading-relaxed'>
				1. General availability varies; please check with the vendors. Vendor
				listing: Intel,
				<a
					href='https://www.intel.com/content/www/us/en/architecture-and-technology/vpro/hardware-shield/threat-detection-technology.html'
					target='_blank'
					rel='noReferrer'
				>
					Intel.com/TDT
				</a>
				  Dependent on security vendors to enable. Please check with your EDR
				vendor if they’ve enabled Intel® Threat Detection Technology.  <br />
				2. OEMs must enable Intel vPro® platform and be Intel vPro® platform
				certified. Not all Intel® Core™ processor-based systems are Intel vPro®
				platform certified.
				<br />
				3. Available on select CPU SKUs when paired with the W680 PCH. ECC
				routing supported in 4L for all DDR4 and DDR5 configurations.
				<br /> 4. Performance hybrid architecture combines two core
				microarchitectures, Performance-cores (P-cores) and Efficient-cores
				(E-cores), on a single processor die first introduced on 12th Gen Intel®
				Core™ processors. Select 12th Gen and newer Intel® Core™ processors do
				not have performance hybrid architecture, only P-cores or E-cores, and
				may have the same cache size. See ark.intel.com for SKU details,
				including cache size and core frequency.
				<br /> 5. Intel® Arc™ GPUs and Intel® Arc™ workstation graphics driver
				support only available on select H-series Intel® Core™ Ultra
				processor-powered systems with at least 16GB dual-channel memory. OEM
				enablement required. Check with OEM or retailer for system configuration
				details. Some certifications currently in progress. Performance varies
				by use, configuration and other factors. Learn more at
				www.Intel.com/PerformanceIndex. Performance results are based on testing
				as of dates shown in configurations and may not reflect all publicly
				available updates. No product or component can be absolutely secure. AI
				features may require software purchase, subscription or enablement by a
				software or platform provider or may have specific configuration or
				compatibility requirements. Details at www.intel.com/PerformanceIndex.
				Results may vary. Intel technologies may require enabled hardware,
				software or service activation. Built into the hardware, Intel® Thread
				Director is provided only in performance hybrid architecture
				configurations of 12th Gen or newer Intel® Core™ processors; OS
				enablement is required. Available features and functionality vary by OS.
				Performance hybrid architecture combines two core microarchitectures,
				Performance-cores (P-cores) and Efficient-cores (E-cores), on a single
				processor die first introduced on 12th Gen Intel® Core™ processors.
				Select 12th Gen and newer Intel® Core™ processors do not have
				performance hybrid architecture, only P-cores or E-cores, and may have
				the same cache size. See ark.intel.com for SKU details, including cache
				size and core frequency. All versions of the Intel vPro® platform
				require an eligible Intel processor, a supported operating system, Intel
				LAN and/or WLAN silicon, firmware enhancements, and other hardware and
				software necessary to deliver the manageability use cases, security
				features, system performance, and stability that define the platform.
				See www.Intel.com/PerformanceIndex/vPro for details. No product or
				component can be absolutely secure. Learn more at
				www.Intel.com/PerformanceIndex (Security & Manageability). Intel is
				committed to the continued development of its renewable, sustainable,
				and green networks as we strive to prioritize greenhouse gas reduction.
				Refer to Intel Corporate Responsibility Report 2021-2022 or visit
				www.Intel.com/2030goals for further information. Intel® Arc™Graphics
				only available on select H-series Intel® Core™ Ultra processor-powered
				systems with at least 16GB dual-channel memory. OEM enablement required.
				Check with OEM or retailer for system configuration details. All Intel®
				Evo™ branded designs must meet demanding thresholds for key mobile user
				experiences like responsiveness and battery life; individual device
				performance may vary. Details at www.intel.com/performance-evo
				Thunderbolt™ and the Thunderbolt™ logo are trademarks of Intel
				<br />
				6. As measured by SoC package power during Microsoft Teams 10-person
				call with Windows Studio Effects on NPU on Intel® Core™ Ultra 7 65H vs.
				AMD Ryzen 7 7840U
				<br />
				As measured by SoC package power during 1x1 Zoom call with background
				blur on NPU on Intel® Core™ Ultra 7 165H vs. AMD Ryzen 7 7840U
				<br />
				Testing by Intel as of 11/30/2023.
				<br />
				Configurations:
				<br />
				Processor: Intel® Core™ Ultra 7 processor 165H (MTL-H) PL1=28W, 16
				Cores; tested on an Intel Internal development system; Memory:
				LPDDR5-7467 2x16GB Dual Rank Storage: Samsung PM9A1 NVMe 512GB; Display
				Resolution: 1920x1080; OS: Microsoft Windows 11 22H2 22621.2215;
				Graphics driver: 31.0.101.5725; BIOS Version:
				MTLPFWI1.R00.3323.D93.2310110906, Power Plan set to Balanced, Power Mode
				set to “Best Power Efficiency”.
				<br />
				Processor: Lenovo T16 AMD Ryzen 7-PRO-7840U processor; Memory:
				LPDDR5-6400 2x16GB Dual Rank; Storage: Samsung 990 PRO NVMe 1TB; Display
				Resolution: 1920x1080; OS: Microsoft Windows11 Pro 22621.2428 (22H2),
				Graphics driver: 31.0.14005.8004; BIOS version: 1.13; Power Plan set to
				Balanced, Power Mode set to “Best Power Efficiency.” 7. Performance
				results are based on testing as of 11/30/2023. Full Configurations:
				<br />
				Processor: Intel® Core™ Ultra 7165H Processor (MTL-H) PL1=28W, 16 Cores;
				tested on an Intel Internal development system; Memory: LPDDR5-7467
				2x16GB Dual Rank Storage: Samsung PM9A1 NVMe 512GB; Display Resolution:
				1920x1080; OS: Microsoft Windows 11 22H2 22621.2215; Graphics driver:
				Pre-production driver; BIOS: Preproduction BIOS, Power Plan set to
				Balanced, Power Mode set to “Best Performance.”
				<br />
				Processor: 13 th Gen Intel® Core™ i7 1370P processor, 14 Core (6P + 8E);
				tested on an Intel Internal development system; Memory: LP LPDDR5-6000
				2x16GB Dual Rank; Storage: Samsung PM9A1 NVMe 512GB; Display Resolution:
				1920x1080; OS: Microsoft Windows 11 Pro 22H2 22621.608, Graphics driver:
				30.0.101.4575; BIOS version: RPLPFWI1.R00.3361.A14.2211151548; Power
				Plan set to Balanced, Power Mode set to “Best Performance.”
				<br />
				Processor: Intel® Core™ Ultra 7165H Processor (MTL-H) PL1=28W, 14 Core
				(6P + 8E); tested on an Intel Internal development system; Memory:
				LPDDR5-7467 2x16GB Dual Rank Storage: Samsung PM9A1 NVMe 512GB; Display
				Resolution: 1920x1080; OS: Microsoft Windows 11 22H2 22621.2215;
				Graphics driver: 31.0.101.5005; BIOS Version:
				MTLPFWI1.R00.3323.D93.2310110906, Power Plan set to Balanced, Power Mode
				set to “Best Performance.”
				<br />
				Processor: Lenovo T16 AMD Ryzen 7-PRO-7840U processor; Memory:
				LPDDR5-6400 2x16GB Dual Rank; Storage: Samsung 990 PRO NVMe 1TB; Display
				Resolution: 1920x1080; OS: Microsoft Windows11 Pro 22621.2428 (22H2),
				Graphics driver: 31.0.14005.8004; BIOS version: 1.13; Power Plan set to
				Balanced, Power Mode set to “Best Performance.” <br />
				Thunderbolt™ and the Thunderbolt™ logo are trademarks of Intel
				Corporation or its subsidiaries in the U.S. and/or other countries. Your
				costs and results may vary.
				<br />
				© Intel Corporation. Intel, the Intel logo, and other Intel marks are
				trademarks of Intel Corporation or its subsidiaries. Other names and
				brands may be claimed as the property of others.
				<br />
			</p>
		</div>
	)
	return (
		<Modal
			title='Disclaimer'
			subtitle={disclaimerContent}
			size={LG}
			footer={footer}
			handleClose={handleClose}
		/>
	)
}
export default DisclaimerModal
