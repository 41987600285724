import { useNavigate } from 'react-router-dom'
import { gameThumbnail, gameThumbnailMobile } from '../../assets/images'
import { playButton } from '../../assets/icons'
import { ROUTES } from '../../constants'

const { PLAY } = ROUTES

const GamePreviewCard = () => {
	const navigate = useNavigate()

	return (
		<div className='game-player relative overflow-hidden h-[630px] flex'>
			<div className='md:block hidden overlay w-full h-1/3 absolute bottom-0 left-0 bg-gradient-to-b from-black/0 from-0% to-black/50 to-50%'></div>
			<img
				src={playButton}
				className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-50 backdrop-blur-sm hover:opacity-75'
				alt='play-icon'
				onClick={() => navigate(PLAY)}
			/>
			<img
				src={gameThumbnail}
				className='hidden md:block w-full h-full object-cover flex-1 gap-4 modal-backdrop '
				alt='thumbnail'
			/>
			<img
				src={gameThumbnailMobile}
				className='md:hidden w-full h-full object-cover-fit flex-1 gap-4 modal-backdrop '
				alt='thumbnail'
			/>
		</div>
	)
}
export default GamePreviewCard
