const Button = ({ name, onClick, classes }) => {
	return (
		<button
			type='button'
			className={`bg-[#0068B5] ${classes} font-medium text-sm px-5 py-2.5`}
			onClick={() => onClick()}
		>
			{name}
		</button>
	)
}

export default Button
