import GameInsights from './GameInsights'
import GamePreviewCard from './GamePreviewCard'
import Footer from './Footer/Footer'

const LandingPage = () => (
	<main className='flex-1 overflow-auto p-6 custom-scrollbar'>
		<div className='flex flex-col gap-6'>
			<GamePreviewCard />
			<GameInsights />
			<Footer />
		</div>
	</main>
)

export default LandingPage
