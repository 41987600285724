import RenderImage from '../assets/badges'

const quizFormatter = (value) => {
	const renderBadge = (grade) => {
		if (grade == 10) return <RenderImage name='goldBadge' />
		else if (grade == 9) return <RenderImage name='silverBadge' />
		else if (grade == 8) return <RenderImage name='bronzeBadge' />
	}

	return (
		<div className='flex items-center'>
			<p>{value}/10</p>
			{renderBadge(value)}
		</div>
	)
}
export default quizFormatter
